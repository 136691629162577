import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const locales = import.meta.glob('../locales/en/**/*.json', { eager: true });

const translation = Object.values(locales).reduce<Record<string, unknown>>(
  (translation, locale) => {
    return {
      ...(typeof locale === 'object' &&
      locale != null &&
      'default' in locale &&
      typeof locale.default === 'object'
        ? locale.default
        : null),
      ...translation,
    };
  },
  {},
);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(() => {
    console.log('Failed to initialize i18n');
  });
